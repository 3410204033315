import React, { Component } from 'react'
import poo from "../src/img/pp.jpg"
import mm from "../src/img/18939.jpg"
import { Paper,Box,Link, Checkbox } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import bb from "../src/img/bb.webp"
import moment from 'moment';
import p1 from "../src/img/p1.webp"
import p2 from "../src/img/p2.webp"
import p3 from "../src/img/p3.webp"
import base_url from './base_url'

import map2 from "../src/img/map2.png"
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    GetFreeTrialForm
    </Typography>,
  ];

export class GetFreeTrialForm extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      Perpus  :"",
      name: "",
      emailid : "",
      mobileno:"",
    
      
    }
    this.handleChange=this.handleChange.bind(this);
  
  }

    
    
    handleChange=(e)=>{
    this.setState({[e.target.name]:e.target.value})
    }
    




  render() {
    return (
      <div>
    
<br/>
<br/>
<br/>
 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
<Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},borderRadius:3}}>
 <Container maxWidth='xl'>
 <br/>
 <Grid container spacing={2}>
 <Grid item xs={12} sm={12}>
 <Box sx={{backgroundColor:'white',minHeight:400,display:'flex',flexDirection:'column',marginLeft:{xs:'2%',sm:'25%'},mr:{xs:'2%',sm:'25%'},borderRadius:5}}>
 <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:'0',sm:'0',md:'4%',},marginRight:{xs:'0',sm:'0',md:'4%',}}}>
 <br/>
 <Typography align='center' sx={{m:2,fontWeight:'bold',color:'#004285'}}>Apply For Free Trial Demo</Typography>
 <TextField value={this.state.Perpus}  onChange={this.handleChange} id="outlined-basic" label="Name" name='Perpus' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
 <TextField value={this.state.name} onChange={this.handleChange} id="outlined-basic" label="Enter email id" name='name' variant="outlined"  size='small' sx={{m:1,fontSize:10}}/>
 <TextField value={this.state.emailid} onChange={this.handleChange} id="outlined-basic" label="Enter phone no" name='emailid' variant="outlined"  size='small' sx={{m:1}}/>
 <TextField  type='date' value={new Date()} id="outlined-basic" label="Which date of free trial you want " name='mobileno' variant="outlined"  size='small' sx={{m:1}}/>
 <TextField value={this.state.emailid} onChange={this.handleChange} id="outlined-basic" label="Exprience in trading" name='emailid' variant="outlined"  size='small' sx={{m:1}}/>
 <TextField value={this.state.emailid} onChange={this.handleChange} id="outlined-basic" label="Tradingview account no" name='emailid' variant="outlined"  size='small' sx={{m:1}}/>


<Box sx={{display:'flex',flexDirection:'row'}}>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
<Checkbox small/>
<Typography sx={{fontSize:13}}>Cash</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',ml:2}}>
<Checkbox small/>
<Typography sx={{fontSize:13}}>Future & Option</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',ml:2}}>
<Checkbox small/>
<Typography sx={{fontSize:13}}>Cash & Future and Option</Typography>
</Box>

</Box>


<br/>


 <Button variant="contained" sx={{width:200,backgroundColor:'green',ml:2}}> submit </Button>


 <br/>
 <br/>
 </Box> 
   



   </Box>
 </Grid>
</Grid>

</Container>
</Box>
<br/>
 </Box>
 
 





      
      </div>
    )
  }
}

export default GetFreeTrialForm


