import React, { Component } from 'react'
import poo from "../src/img/pp.jpg"
import { Paper,Box,Link } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';

import AdjustIcon from '@mui/icons-material/Adjust';

import imgf from "../src/img/imgf.jpg"
import map2 from "../src/img/ggg.png"
import snp from "../src/img/snp.webp"

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    career
    </Typography>,
  ];

export class About extends Component {
  render() {
    return (
      <div>
      <br/>
      <br/>
      <Box sx={{backgroundImage:{xs:`url(${poo})`,sm:`url(${poo})`},width:'100%',display:'flex',height:{xs:200,sm:250,md:310},backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
      <br/>
      <br/>
      <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},mt:3,width:'100%'}}>
      <Container maxWidth='xl'>
      <Box sx={{background:'rgba(28,68,106,.7)',height:{xs:140,sm:180,md:200}, width:'100%',mt:1,}}>
      <Box sx={{mt:2}}>
      <br/>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>ABOUT THE MTEL</Typography>
       <Typography align='center' sx={{color:'#fff',fontWeight:'900',fontSize:{xs:17,sm:17,md:21},fontFamily:'inherit'}}>TECH</Typography>
      </Box>


      <Box sx={{display:'flex',justifyContent:'center',mt:{xs:2,sm:2,md:5}}}>
      <Box sx={{backgroundColor:'#c7167a',height:40,width:150,borderRadius:10,}}>
<Box sx={{display:'flex',justifyContent:'center'}} >
<Box>
<Typography sx={{mt:1,color:'white'}}>Learn</Typography>
</Box>
</Box>
      </Box>
      </Box>
      </Box>
      </Container>
      </Box> 
 </Box>




 <Box sx={{minHeight:300,backgroundColor:'#e8eeee ',opacity:0.9,backgroundRepeat:''}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
 <Box sx={{mt:1,mb:1,minHeight:300,}}>
 
 <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{display:'flex',flexDirection:'column'}}>
  <br/>
  <Typography variant='h1'  sx={{fontSize:{xs:22,sm:22,md:28},fontWeight:'800',fontFamily:'sans-serif'}}>We didn't find it for us,</Typography>
  <Typography variant='h1'  sx={{fontSize:{xs:22,sm:22,md:28},fontWeight:'800',mt:1,fontFamily:'sans-serif',color:'#c7167a '}}>so we created it for you</Typography>
  <br/>
  <Typography align='justify' sx={{mb:2,mr:{xs:'0',sm:'0',md:'10%',},fontWeight:'335',fontFamily: 'Roboto Slab',color:'black',fontSize:{xs:12,sm:12,md:13},lineHeight:2}}>That's essentially our story in one sentence.
  Mtel Tech has quickly established itself as a leading provider of advanced technical analysis software for the global stock market.<br/> We specialize in offering cutting-edge solutions designed to empower traders, investors, and financial institutions with the tools needed to make informed and strategic market decisions.
At Mtel Tech, our mission is to enhance the efficiency and precision of stock market analysis through innovative software.<br/> We combine sophisticated algorithms with user-friendly interfaces to deliver powerful charting, technical indicators, backtesting features, and real-time market data. Our software solutions are trusted by professionals across the trading industry for their accuracy, reliability, and ease of use.
With a strong focus on innovation and customer satisfaction, Mtel Tech continues to push the boundaries of technical analysis, helping our users stay ahead of market trends and maximize their investment potential.
Whether you’re an individual trader or a financial institution, Mtel Tech provides the tools and insights you need to navigate today’s complex financial markets.
</Typography>
  </Box>
  
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>

  <Box sx={{m:{xs:'0',sm:'0',md:'2'}}}>
  <Box sx={{borderRadius:10,height:370,}}>
  <Box
     component="img"
     sx={{
       height:370,
       display: 'block',
     objectFit:'cover',
       overflow: 'hidden',
       width:'100%',
       borderRadius:10
     }}
     src={imgf}
     alt=''
   />
  </Box>
  </Box>



    
  </Grid>
</Grid>
 </Box>
 <br/>
 </Container>
</Box>
 </Box>








 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3}}}>
  <Container maxWidth='xl'>
  <br/>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
   <Box sx={{minHeight:250,}}>

   <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>The Indian stock market, often referred to as "Dalal Street," has witnessed tremendous growth and development over the years. It is home to a diverse range of companies, from established conglomerates to innovative startups. !</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>These companies play a crucial role in driving India's economic growth and providing investment opportunities for both domestic and international investors.. </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>One of the most prominent features of the Indian stock market is its large number of publicly listed companies. These companies represent a wide spectrum of industries, including information technology, pharmaceuticals, banking and finance, automobiles, and consumer goods.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}> Some of the most well-known Indian stock market companies include Reliance Industries, Tata Consultancy Services (TCS), Infosys, HDFC Bank, and Mahindra & Mahindra..</Typography>
</Box>
</Box>



   </Box>
  </Grid>
  <Grid item xs={12} sm={12} md={6} lg={6}>
  <Box sx={{minHeight:250,}}>

  <Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>The Indian stock market is also characterized by its vibrant startup ecosystem. In recent years, India has emerged as a global hub for technology startups, with companies like Paytm, Nykaa, and Zomato making significant strides.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>Many of these startups have gone public through initial public offerings (IPOs), providing investors with opportunities to participate in their growth.. </Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>The performance of Indian stock market companies is influenced by various factors, including macroeconomic conditions, industry trends, and geopolitical events. The Indian economy's strong fundamentals and growing consumer base have contributed to the overall bullish sentiment in the market.</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:15,width:15,mr:1,color:'white',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,fontWeight:'335',fontFamily: 'Roboto Slab',color:'#fff',fontSize:{xs:11,sm:11,md:13,lg:13}}}>However, investors should also be aware of the inherent risks associated with investing in stocks, such as market volatility and company-specific risks..</Typography>
</Box>
</Box>


  </Box>
  </Grid>
 </Grid>
 
 </Container>
 </Box>
 <br/>
  </Box>






 










      
      </div>
    )
  }
}

export default About








