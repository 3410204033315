import logo from './logo.svg';
import './App.css';
import Main from './Main';
import { Box } from '@mui/material';

function App() {
  return (
    <div>
  
     <Main/>
    </div>
  );
}

export default App;
