import React, { Component } from 'react'
import { Container,Typography,Card,Grid,Divider,TextField,Button,Box, Grid2, Paper} from '@mui/material';
import bb from './bg.svg'
import SsidChartIcon from '@mui/icons-material/WhatsApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import o1 from './img_svg/1.png'
import o2 from './img_svg/2.png'
import o3 from './img_svg/3.png'


export class Pricing extends Component {
  render() {
    return (
      <div>
      <Box sx={{backgroundColor:'#fff',minHeight:450}}>
<Box sx={{minHeight:'600',backgroundImage:`url(${bb})`,width:'100%',backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat',}}>
<Container maxWidth='lg'>
    <Box sx={{pt:10}}>
 <br/>
 <Typography variant='h1' textAlign='center' sx={{color:'black',fontSize:{xs:30,sm:30,md:45},fontWeight:'800'}}>Plans & Pricing ➞</Typography>
 <Typography textAlign='center' sx={{textTransform:'uppercase',fontSize:13,color:'#4c4c4c',mt:1,fontWeight:'bold'}}>-----AFFORDABLE PLAN AND PRICING FOR EVERYONE-----</Typography>
 <br/>
 <br/>
 
<Grid2 container spacing={0}>
<Grid2 item size={{xs:12,sm:12,md:4}}>
<Paper elevation={1} variant='outlined' sx={{height:700,width:'100%',borderRadius:0}}>

<Box sx={{display:'flex',justifyContent:'right',alignSelf:'right'}}>
  <Box sx={{height:25,minWidth:'40%',backgroundColor:'#fe9f43',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{color:'black',pl:2,pr:2}}>On sale – Save 30% off</Typography>
  </Box>
</Box>

<Box sx={{m:3}}>


<Box>
 <img src={o1} style={{height:50,width:50,objectFit:'contain'}}/> 
</Box>


<Typography sx={{fontSize:30,fontWeight:'700',color:'black',mt:2}}>1-Month</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Charts + Indicator + Strategy</Typography>
<Typography sx={{fontSize:30,fontWeight:'600',color:'black',mt:1}}>₹ 14,999</Typography>

<Box sx={{display:'flex',flexDirection:'row'}}>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Previously was ₹</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f',ml:0.3,textDecoration:' line-through'}}>19,999</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:3,justifyContent:'space-between',alignItems:'center',width:'100%'}}>
<Box  sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
 <SsidChartIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>On sale – Save 30% off</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:2.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>1-Month</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Subscription</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Day</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Training</Typography> 
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>TradingView</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Essential</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Month</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Breakouts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Indicator</Typography>
</Box>





<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Daily Watchlist</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Before 9.00 AM</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Realtime Alerts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Mobile / PC</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Sound / Pop-ups</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Alerts</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>24X7 Support</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Call/Chat/Email</Typography>
</Box>

<br/>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Box sx={{height:37,width:230,backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center', '&:hover': {
            bgcolor: '#fe9f43',
          },}}>
<Typography sx={{color:'#fff',fontSize:14,fontWeight:'bold'}}>PAY NOW</Typography>
<KeyboardDoubleArrowRightIcon sx={{color:'#fff'}}/>
</Box>
</Box>








</Box>
</Paper>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:4}}>
<Paper elevation={1} variant='outlined' sx={{height:700,width:'100%',borderRadius:0}}>

<Box sx={{display:'flex',justifyContent:'right',alignSelf:'right'}}>
  <Box sx={{height:25,minWidth:'40%',backgroundColor:'#fe9f43',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{color:'black',pl:2,pr:2}}>On sale – Save 30% off</Typography>
  </Box>
</Box>

<Box sx={{m:3}}>


<Box>
 <img src={o2} style={{height:50,width:50,objectFit:'contain'}}/> 
</Box>


<Typography sx={{fontSize:30,fontWeight:'700',color:'black',mt:2}}>6-Month</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Charts + Indicator + Strategy</Typography>
<Typography sx={{fontSize:30,fontWeight:'600',color:'black',mt:1}}>₹ 59,999</Typography>

<Box sx={{display:'flex',flexDirection:'row'}}>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Previously was ₹</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f',ml:0.3,textDecoration:' line-through'}}>69,999</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:3,justifyContent:'space-between',alignItems:'center',width:'100%'}}>
<Box  sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
 <SsidChartIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>PAY NOW -> 30% OFF</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:2.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>6-Months</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Subscription</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Day</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Training</Typography> 
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>TradingView</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Essential</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>6-Months</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Breakouts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Indicator</Typography>
</Box>





<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Daily Watchlist</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Before 9.00 AM</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Realtime Alerts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Mobile / PC</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Sound / Pop-ups</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Alerts</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>24X7 Support</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Call/Chat/Email</Typography>
</Box>

<br/>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Box sx={{height:37,width:230,backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center', '&:hover': {
            bgcolor: '#fe9f43',
          },}}>
<Typography sx={{color:'#fff',fontSize:14,fontWeight:'bold'}}>PAY NOW</Typography>
<KeyboardDoubleArrowRightIcon sx={{color:'#fff'}}/>
</Box>
</Box>








</Box>
</Paper>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:4}}>
<Paper elevation={1} variant='outlined' sx={{height:700,width:'100%',borderRadius:0}}>

<Box sx={{display:'flex',justifyContent:'right',alignSelf:'right'}}>
  <Box sx={{height:25,minWidth:'40%',backgroundColor:'#fe9f43',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{color:'black',pl:2,pr:2}}>On sale – Save 30% off</Typography>
  </Box>
</Box>

<Box sx={{m:3}}>


<Box>
 <img src={o3} style={{height:50,width:50,objectFit:'contain'}}/> 
</Box>


<Typography sx={{fontSize:30,fontWeight:'700',color:'black',mt:2}}>1-Year</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Charts + Indicator + Strategy</Typography>
<Typography sx={{fontSize:30,fontWeight:'600',color:'black',mt:1}}>₹ 99,999</Typography>

<Box sx={{display:'flex',flexDirection:'row'}}>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Previously was ₹</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f',ml:0.3,textDecoration:' line-through'}}>1,20,999</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:3,justifyContent:'space-between',alignItems:'center',width:'100%'}}>
<Box  sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
 <SsidChartIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>PAY NOW -> 30% OFF</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:2.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>1-Years</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Subscription</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Day</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Training</Typography> 
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>TradingView</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Essential</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Year</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Breakouts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Indicator</Typography>
</Box>





<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Daily Watchlist</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Before 9.00 AM</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Realtime Alerts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Mobile / PC</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Sound / Pop-ups</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Alerts</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>24X7 Support</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Call/Chat/Email</Typography>
</Box>

<br/>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Box sx={{height:37,width:230,backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center', '&:hover': {
            bgcolor: '#fe9f43',
          },}}>
<Typography sx={{color:'#fff',fontSize:14,fontWeight:'bold'}}>PAY NOW</Typography>
<KeyboardDoubleArrowRightIcon sx={{color:'#fff'}}/>
</Box>
</Box>


</Box>
</Paper>
</Grid2>
</Grid2>
</Box>





<br/>





<Box sx={{pt:2}}>
 <br/>
 <Typography variant='h1' textAlign='center' sx={{color:'black',fontSize:{xs:30,sm:30,md:45},fontWeight:'800'}}>Cash Plans & Pricing ➞</Typography>
 <Typography textAlign='center' sx={{textTransform:'uppercase',fontSize:13,color:'#4c4c4c',mt:1,fontWeight:'bold'}}>-----AFFORDABLE PLAN AND PRICING FOR EVERYONE-----</Typography>
 <br/>
 <br/>
 
<Grid2 container spacing={0}>
<Grid2 item size={{xs:12,sm:12,md:4}}>
<Paper elevation={1} variant='outlined' sx={{height:700,width:'100%',borderRadius:0}}>

<Box sx={{display:'flex',justifyContent:'right',alignSelf:'right'}}>
  <Box sx={{height:25,minWidth:'40%',backgroundColor:'#fe9f43',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{color:'black',pl:2,pr:2}}>On sale – Save 30% off</Typography>
  </Box>
</Box>

<Box sx={{m:3}}>


<Box>
 <img src={o1} style={{height:50,width:50,objectFit:'contain'}}/> 
</Box>


<Typography sx={{fontSize:30,fontWeight:'700',color:'black',mt:2}}>1-Months</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Charts + Indicator + Strategy</Typography>
<Typography sx={{fontSize:30,fontWeight:'600',color:'black',mt:1}}>₹ 19,999</Typography>

<Box sx={{display:'flex',flexDirection:'row'}}>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Previously was ₹</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f',ml:0.3,textDecoration:' line-through'}}>29,999</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:3,justifyContent:'space-between',alignItems:'center',width:'100%'}}>
<Box  sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
 <SsidChartIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>On sale – Save 30% off</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:2.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>1-Month</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Subscription</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Day</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Training</Typography> 
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>TradingView</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Essential</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Month</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Breakouts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Indicator</Typography>
</Box>





<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Daily Watchlist</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Before 9.00 AM</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Realtime Alerts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Mobile / PC</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Sound / Pop-ups</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Alerts</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>24X7 Support</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Call/Chat/Email</Typography>
</Box>

<br/>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Box sx={{height:37,width:230,backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center', '&:hover': {
            bgcolor: '#fe9f43',
          },}}>
<Typography sx={{color:'#fff',fontSize:14,fontWeight:'bold'}}>PAY NOW</Typography>
<KeyboardDoubleArrowRightIcon sx={{color:'#fff'}}/>
</Box>
</Box>








</Box>
</Paper>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:4}}>
<Paper elevation={1} variant='outlined' sx={{height:700,width:'100%',borderRadius:0}}>

<Box sx={{display:'flex',justifyContent:'right',alignSelf:'right'}}>
  <Box sx={{height:25,minWidth:'40%',backgroundColor:'#fe9f43',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{color:'black',pl:2,pr:2}}>On sale – Save 30% off</Typography>
  </Box>
</Box>

<Box sx={{m:3}}>


<Box>
 <img src={o2} style={{height:50,width:50,objectFit:'contain'}}/> 
</Box>


<Typography sx={{fontSize:30,fontWeight:'700',color:'black',mt:2}}>6-Month</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Charts + Indicator + Strategy</Typography>
<Typography sx={{fontSize:30,fontWeight:'600',color:'black',mt:1}}>₹ 99,999</Typography>

<Box sx={{display:'flex',flexDirection:'row'}}>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Previously was ₹</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f',ml:0.3,textDecoration:' line-through'}}>1,20,999</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:3,justifyContent:'space-between',alignItems:'center',width:'100%'}}>
<Box  sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
 <SsidChartIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>PAY NOW -> 30% OFF</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:2.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>6-Months</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Subscription</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Day</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Training</Typography> 
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>TradingView</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Essential</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>6-Month</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Breakouts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Indicator</Typography>
</Box>





<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Daily Watchlist</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Before 9.00 AM</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Realtime Alerts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Mobile / PC</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Sound / Pop-ups</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Alerts</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>24X7 Support</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Call/Chat/Email</Typography>
</Box>

<br/>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Box sx={{height:37,width:230,backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center', '&:hover': {
            bgcolor: '#fe9f43',
          },}}>
<Typography sx={{color:'#fff',fontSize:14,fontWeight:'bold'}}>PAY NOW</Typography>
<KeyboardDoubleArrowRightIcon sx={{color:'#fff'}}/>
</Box>
</Box>








</Box>
</Paper>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:4}}>
<Paper elevation={1} variant='outlined' sx={{height:700,width:'100%',borderRadius:0}}>

<Box sx={{display:'flex',justifyContent:'right',alignSelf:'right'}}>
  <Box sx={{height:25,minWidth:'40%',backgroundColor:'#fe9f43',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{color:'black',pl:2,pr:2}}>On sale – Save 30% off</Typography>
  </Box>
</Box>

<Box sx={{m:3}}>


<Box>
 <img src={o3} style={{height:50,width:50,objectFit:'contain'}}/> 
</Box>


<Typography sx={{fontSize:30,fontWeight:'700',color:'black',mt:2}}>1-Year</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Charts + Indicator + Strategy</Typography>
<Typography sx={{fontSize:30,fontWeight:'600',color:'black',mt:1}}>₹ 1,49,999</Typography>

<Box sx={{display:'flex',flexDirection:'row'}}>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f'}}>Previously was ₹</Typography>
<Typography sx={{fontSize:14,fontWeight:'550',color:'#1d294f',ml:0.3,textDecoration:' line-through'}}>1,79,999</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',mt:3,justifyContent:'space-between',alignItems:'center',width:'100%'}}>
<Box  sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
 <SsidChartIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>PAY NOW -> 30% OFF</Typography>
</Box>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:2.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>1-Year</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Subscription</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Day</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Training</Typography> 
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>TradingView</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Essential</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>1-Year</Typography>
</Box>



<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Breakouts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Indicator</Typography>
</Box>





<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Daily Watchlist</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Before 9.00 AM</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Realtime Alerts</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Mobile / PC</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>Sound / Pop-ups</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>on Alerts</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'left',alignItems:'center',mt:1.5}}> 
<ArrowForwardIosIcon  sx={{color:'#fe9f43',height:15,width:15}}/> <Typography sx={{ml:1,fontSize:14,fontWeight:'600',color:'black'}}>24X7 Support</Typography> <Typography sx={{ml:1,fontSize:14,fontWeight:'500',color:'black'}}>Call/Chat/Email</Typography>
</Box>

<br/>


<Box sx={{height:50,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
<Box sx={{height:37,width:230,backgroundColor:'black',display:'flex',justifyContent:'center',alignItems:'center', '&:hover': {
            bgcolor: '#fe9f43',
          },}}>
<Typography sx={{color:'#fff',fontSize:14,fontWeight:'bold'}}>PAY NOW</Typography>
<KeyboardDoubleArrowRightIcon sx={{color:'#fff'}}/>
</Box>
</Box>








</Box>
</Paper>
</Grid2>
</Grid2>


<br/>
<Typography sx={{fontSize:15,color:'#4c4c4c',fontWeight:'500'}}>Note: The Subscriptions charges are mentioned above are only for Software, training, educations and strategy setups only. Please note we do not provide any tips or advisory services and also please ask your financial advisor before buying or selling with your real money.</Typography>
<br/>
<br/>
</Box>





 </Container>
 </Box>
      </Box>
      </div>
    )
  }
}

export default Pricing