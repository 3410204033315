import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Container, Divider, Button, Link } from "@mui/material";
import logo from "../src/img/logo2.png";
import PersonIcon from "@mui/icons-material/Person";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "./Footer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { keyframes } from "@mui/system";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ww from './ww.webp'


const blink = keyframes`
  from { opacity: 0; color:#fff;}
  to { opacity: 1; color:#e50000; }
`;

/*
const router = createBrowserRouter([

]);
*/

export class Appheader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      open: false,
    };
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setAnchorEl(event.currentTarget);
  }
  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: !this.state.open,
    });
  }
  handleClose() {
    this.setAnchorEl(null);
  }

  renderMenu() {
    return (
      <Box sx={{}}>
        <Menu
          id="fade-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <MenuItem component="a" href="/" onClick={this.handleClose}>
           Home
          </MenuItem>
          <MenuItem component="a" href="/about" onClick={this.handleClose}>
            About Us
          </MenuItem>
          <MenuItem component="a" href="/contactus" onClick={this.handleClose}>
            Contact us
          </MenuItem>
          <MenuItem component="a" href="/getFreeTrialForm" onClick={this.handleClose}>
           Trial Demo
          </MenuItem>
        </Menu>
      </Box>
    );
  }

  render() {
    return (
      <div>
        <Box>
          <AppBar
            position="fixed"            
            sx={{
              backgroundColor: "#ffffff",
              borderBottom: 2,
              borderBottomColor: "#c7167a",
            }}
          >
            <Container maxWidth="xl">
              <Toolbar variant="dense">
                <Box
                  sx={{ flex: 1, marginLeft: { xs: -3, sm: 0, md: 0, lg: 0 } }}
                >
                  <img
                    alt="logo"
                    src={logo}
                    style={{ height: 55, objectFit: "contain" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "right",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
              

                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                          ml:1,mr:1
                        }}
                      >
                             <Link
                               component="a"
                               href="/"
                               sx={{
                                 fontSize: 15,
                                 fontWeight: "500",
                                 textDecoration: "none",
                                 color: "black",
                                 display:{xs:'none',sm:'block'},
                                 '&:hover': {
            color: '#fe9f43',
          }
                               }}
                             >
                               Home
                             </Link>

                      </Box>
                      
                
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                          ml:1,mr:1
                        }}
                      >
                             <Link
                               component="a"
                               href="/about"
                               sx={{
                                 fontSize: 15,
                                 fontWeight: "500",
                                 textDecoration: "none",
                                 color: "black",
                                 display:{xs:'none',sm:'block'},
                                 '&:hover': {
            color: '#fe9f43',
          }
                               }}
                             >
                               About Us
                             </Link>

                      </Box>

                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                          ml:1,mr:1
                        }}
                      >
                             <Link
                               component="a"
                               href="/contactus"
                               sx={{
                                 fontSize: 15,
                                 fontWeight: "500",
                                 textDecoration: "none",
                                 display:{xs:'none',sm:'block'},
                                 color: "black",
                                 '&:hover': {
            color: '#fe9f43',
          }
                               }}
                             >
                               Contact Us
                             </Link>

                      </Box>

                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                          },
                          alignItems: "center",
                          ml:1,mr:1
                        }}
                      >
                             <Link
                               component="a"
                               href="/getFreeTrialForm"
                               sx={{
                                 fontSize: 15,
                                 fontWeight: "500",
                                 textDecoration: "none",
                                 display:{xs:'none',sm:'block'},
                                 color: "black",
                                 '&:hover': {
            color: '#fe9f43',
          }
                               }}
                             >
                               Trial Demo
                             </Link>

                      </Box>


                    </Box>

                    <Box
                      sx={{
                        height: 35,
                        width: 90,
                        backgroundColor: "#fe9f43",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 4,
                        mr: 2,
                        animation: `${blink} 2s linear infinite`,
                      }}
                    >
                      <Link
                        component="a"
                        href="/pricing"
                        sx={{
                          fontSize: 15,
                          fontWeight: "550",
                          textDecoration: "none",
                          color: "black",
                          '&:hover': {
            bgcolor: '#fe9f43',
          }
                        }}
                      >
                        Pricing
                      </Link>
                    </Box>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 0 }}
                    >
                      <PersonIcon
                        sx={{ color: "black", height: "60", width: "50" }}
                      />
                    </IconButton>

                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ marginRight: { xs: 0, sm: 0, md: 0, lg: 0 },display:{xs:'block',sm:'none'} }}
                      aria-owns={this.state.open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      <MenuIcon
                        sx={{ color: "black", height: "60", width: "50" }}
                      />
                    </IconButton>
                    {this.renderMenu()}
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <Divider sx={{ borderBottomWidth: 6 }} />

          <Box>
            {/*
    <React.StrictMode>
     <RouterProvider router={router} />
   </React.StrictMode>
   */}

            <Box
              sx={{ display: "flex", justifyContent: "right" }}
              component="a"
              href="https://wa.me/+91-7801918155"
            >
              <Fab
                variant="circular"
                size="medium"
                aria-label="add"
                sx={{
                  position: "fixed",
                  bottom: 20,
                  right: 10,
                //  color: "white",
                 backgroundColor:'transparent'
                }}
              >
                <img src={ww} style={{height:50,width:50}}/>
              </Fab>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

export default Appheader;
