import React, { Component } from "react";
import { Box, Container, Divider, Grid2, Paper, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import base_url from "./base_url";
import bg from '../src/bg.jpg'
import {Link, Button,Card} from "@mui/material";
import Grid from "@mui/material/Grid2";
import animation from '../src/animation.gif'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallMadeOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AddHomeIcon from '@mui/icons-material/AddHome';
import Groups2Icon from '@mui/icons-material/Groups2';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ff from '../src/ff.png'
import cc from '../src/new_img/cc.png'
import ll from '../src/new_img/ll.png'
import AdjustIcon from '@mui/icons-material/Adjust';
import rr from '../src/new_img/rr.png'
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import LaptopIcon from '@mui/icons-material/Laptop';
import st from '../src/new_img/st.png'
import GradeIcon from '@mui/icons-material/Grade';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { keyframes } from '@mui/system';
import roc from '../src/new_img/roc.png'
import lg from '../src/new_img/lg.jpeg'
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

/*
0%, 49% {
    background-color: rgb(117, 209, 63);
    border: 3px solid #e50000;
  }
  50%, 100% {
    background-color: #e50000;
    border: 3px solid rgb(117, 209, 63);
  }
    */

const blink = keyframes`
  from { opacity: 0; color:#fff;}
  to { opacity: 1; color:#e50000; }
`;
export class Landingpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "one",

      is_visible: true,
    };
  }



  render() {
    return (
      <Box sx={{height:'100%',width:'100%'}}>

        <Box
          sx={{
            height: 36,
            width: "100%",
            backgroundColor: "#f3eae6",
            display: "flex",
            justifyContent: "center",
            mt: 7,
          }}
        >
          <Marquee>
          <Typography
                  sx={{ fontSize: 12, color: "red", display: "flex",textTransform:'uppercase',mr:1,ml:30}}
                >
               DISCLAIMER*
          </Typography>
            {listInstruction.map((e,i) => (
              <Box sx={{ display: "flex",mr:0.5 }}>
                <Typography
                  sx={{ fontSize: 12, color: "#07497b", display: "flex",textTransform:'uppercase',color:'#fe9f43'}}
                >
                 {e}
                </Typography>
              </Box>
            ))}
          </Marquee>
        </Box>


{
  /// rgba(32, 35, 72,0.88)
}

      <Box sx={{minHeight:400,backgroundImage:`url(${bg})`,width:'100%',backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat',}}>
      <Box sx={{backgroundColor:'rgba(2,7,13,0.98)',minHeight:{xs:400},width:'100%'}}>
      <Container maxWidth='lg'>

    <Grid container spacing={1}>
    <Grid item size={{xs:12,sm:12,md:7}}>
     <Box sx={{backgroundColor:'transparent',height:'100%'}}>
      <br/>
      <br/>
      <br/>
<Box sx={{display:'flex',flexDirection:{xs:'column',sm:'column',md:'row'}}}>
<Typography textAlign='center' sx={{fontSize:{xs:28,sm:33},fontWeight:'900',mt:{xs:2,sm:2},color:'#01cfe6'}}>Technical Analysis </Typography>
<Typography textAlign='center' sx={{fontSize:{xs:28,sm:33},fontWeight:'900',ml:1,color:'#fff', animation: `${blink} 2s linear infinite`,mt:{xs:1,sm:1,md:2}}}>Software</Typography>
</Box>


<Box sx={{display:'flex',flexDirection:'row' ,}}>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:{xs:'center',sm:'center',md:'left'},alignItems:'center',mt:3}}>
<AssessmentIcon sx={{ml:1.5,color:'#fff',height:22,width:22,mr:0.5}}/>
<Typography sx={{textTransform:'uppercase',color:'#fff',fontWeight:'600',fontSize:{xs:12,sm:13,md:15}}}>CHART PATTERNS</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:{xs:'center',sm:'center',md:'left'},alignItems:'center',mt:3,ml:2}}>
<NotificationsNoneOutlinedIcon sx={{ml:1.5,color:'#fff',height:22,width:22,mr:0.5}}/>
<Typography sx={{textTransform:'uppercase',color:'#fff',fontWeight:'600',fontSize:{xs:12,sm:13,md:15}}}>ALERTS</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:{xs:'center',sm:'center',md:'left'},alignItems:'center',mt:3,ml:2}}>
<AutoGraphIcon sx={{ml:1.5,color:'#fff',height:22,width:22,mr:0.5}}/>
<Typography sx={{textTransform:'uppercase',color:'#fff',fontWeight:'600',fontSize:{xs:12,sm:13,md:15}}}>Volume Gainer / Loser </Typography>
</Box>


</Box>


<br/>
<Box sx={{mt:4}}>
<Grid2 container spacing={1}>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Know your trades before 5min via alert and notification.</Typography>
</Box>
</Box>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Software demo available.</Typography>
</Box>
</Box>
</Grid2>
<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Catch top gainer and top loosers at market opening.</Typography>
</Box>
</Box>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Cash, future and option guidance.</Typography>
</Box>
</Box>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Scan all the stock and provide you the best scan.</Typography>
</Box>
</Box>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Software available for tablet / mobile.</Typography>
</Box>
</Box>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Real time NSE, OPTIONS, FNO price</Typography>
</Box>
</Box>
</Grid2>

<Grid2 item size={{xs:12,sm:12,md:6}} >
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:20,width:20,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#fe9f43',fontSize:{xs:12,sm:12,md:12},textTransform:'uppercase',fontWeight:'600'}}>Trade in multiple way intraday / positional </Typography>
</Box>
</Box>
</Grid2>

</Grid2>
</Box>





     </Box>
    </Grid>
    <Grid item size={{xs:12,sm:12,md:5}}>
    <Box sx={{minHeight:200,mt:{xs:0,sm:6}}}>
    <Box sx={{height:450,width:'100%',display:{xs:'none',sm:'none',md:'flex',},flexDirection:'column',justifyContent:'right'}}>
   
    <img src={lg} style={{height:400,width:'100%',objectFit:'contain'}}/>

    </Box>
    <Box sx={{display:{xs:'flex',sm:'flex',md:'none'},justifyContent:'right',mt:-3}}>
    <img src={lg} style={{height:400,width:'100%',objectFit:'contain'}}/>
   </Box>
    </Box>
    </Grid>
  </Grid>

  </Container>
  </Box>
         </Box>




<Box sx={{mt:2}}>
<Typography sx={{fontWeight:'700',textAlign:'center',mb:1}}>TradingView Graph</Typography>
<Container maxWidth='lg'>
<Box sx={{minHeight:100,width:'100%',backgroundColor:'#fff',borderRadius:2}}>

<Box sx={{ml:1,mr:1,pt:1,pb:1}}>
<img style={{height:'auto',width:'100%',objectFit:'cover',borderRadius:3}} src={st}/>
</Box>

</Box>
</Container>
</Box>









<Box sx={{mt:2}}>
<Container maxWidth='lg'>
<Paper elevation={1} sx={{minHeight:380,width:'100%'}}>
<Grid container spacing={1}>
<Grid item size={{xs:12,sm:7}}>
<Box sx={{minHeight:200,margin:2}}>

<img src={ll} style={{maxHeight:400,width:'100%',objectFit:'contain'}}/>

</Box>
</Grid>
<Grid item size={{xs:12,sm:5}}>
<Box sx={{padding:2}}>
<Typography textAlign='left' sx={{fontWeight:'700',fontSize:{xs:25,sm:32},textTransform:'uppercase',mt:2}}>FEATURES</Typography>
<Box sx={{height:3,width:{xs:150,sm:180},backgroundColor:'#fe9f43'}}></Box>


<br/>
<Box sx={{display:'flex',flexDirection:'row'}}>
<AdjustIcon sx={{height:24,width:24,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#121111',fontSize:{xs:12,sm:12,md:14}}}>Indicator = non repaient.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',mt:0.5}}>
<AdjustIcon sx={{height:24,width:24,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#121111',fontSize:{xs:12,sm:12,md:14}}}>Alert = that don't expire.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',mt:0.5}}>
<AdjustIcon sx={{height:24,width:24,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#121111',fontSize:{xs:12,sm:12,md:14}}}>Chart = tack by tick data by TradingView.</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',mt:0.5}}>
<AdjustIcon sx={{height:24,width:24,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#121111',fontSize:{xs:12,sm:12,md:14}}}>Apps  = apps for past performance</Typography>
</Box>
</Box>

<Box sx={{display:'flex',flexDirection:'row',mt:0.5}}>
<AdjustIcon sx={{height:24,width:24,mr:1,color:'#fe9f43',mt:0.4}}/>
<Box sx={{display:'flex',alignItems:'center'}}>
<Typography sx={{mb:2,color:'#121111',fontSize:{xs:12,sm:12,md:14}}}>Event = real time</Typography>
</Box>
</Box>




</Box>
</Grid>
</Grid>
</Paper>
</Container>
</Box>





<Box sx={{mt:2,mb:1}}>
  <Container maxWidth='lg'>
   <Grid container spacing={{xs:1,sm:2,md:3}}>
    <Grid item size={{xs:12,sm:3}}>
        
    <Grid container spacing={1}>
      <Grid item size={{xs:12,sm:12}}>
      <Box sx={{width:'100%'}}>
           <img src={cc} style={{maxHeight:500,width:'100%',objectFit:'contain'}}/>  
        </Box>
      </Grid>
    </Grid>


    </Grid>
    <Grid item size={{xs:12,sm:9}}>
<Box sx={{width:'100%',minHeight:500}}>
<Typography textAlign='left' sx={{fontWeight:'700',fontSize:{xs:22,sm:28},textTransform:'uppercase',mt:1}}>LIVE ALERTS</Typography>
<Box sx={{height:3,width:{xs:180,sm:180},backgroundColor:'#fe9f43'}}></Box>

<Grid container sx={{mt:2}} spacing={{xs:2,sm:2,md:4}}>
<Grid item size={{xs:6,sm:4,}}>
<Paper elevation={1} sx={{height:200,width:'100%',backgroundColor:'#fff',borderRadius:10,}}>
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<Box sx={{height:80,width:80,backgroundColor:'#09488b',borderRadius:25,mt:3,display:'flex',justifyContent:'center',alignItems:'center'}}>
<NotificationsIcon sx={{height:50,width:50,color:'#fff'}}/>
</Box>


<Typography textAlign='center' sx={{ml:3,mr:3,fontWeight:'600',mt:2}}>Realtime Alerts
and Notifications</Typography>


</Box>
</Paper>
</Grid>
<Grid item size={{xs:6,sm:4}}>
<Paper elevation={1} sx={{height:200,width:'100%',backgroundColor:'#fff',borderRadius:10}}>
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<Box sx={{height:80,width:80,backgroundColor:'#09488b',borderRadius:25,mt:3,display:'flex',justifyContent:'center',alignItems:'center'}}>
<CloudSyncIcon sx={{height:50,width:50,color:'#fff'}}/>
</Box>


<Typography textAlign='center' sx={{ml:3,mr:3,fontWeight:'600',mt:2}}>Breakout<br/>
Alerts</Typography>


</Box>
</Paper>
</Grid>
<Grid item size={{xs:6,sm:4}}>
<Paper elevation={1} sx={{height:200,width:'100%',backgroundColor:'#fff',borderRadius:10}}>
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<Box sx={{height:80,width:80,backgroundColor:'#09488b',borderRadius:25,mt:3,display:'flex',justifyContent:'center',alignItems:'center'}}>
<MobileFriendlyIcon sx={{height:50,width:50,color:'#fff'}}/>
</Box>


<Typography textAlign='center' sx={{ml:3,mr:3,fontWeight:'600',mt:2}}>Live Mobile<br/>
Notifications</Typography>


</Box>
</Paper>
</Grid>
<Grid item size={{xs:6,sm:4}}>
<Paper elevation={1} sx={{height:200,width:'100%',backgroundColor:'#fff',borderRadius:10}}>
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<Box sx={{height:80,width:80,backgroundColor:'#09488b',borderRadius:25,mt:3,display:'flex',justifyContent:'center',alignItems:'center'}}>
<LaptopIcon sx={{height:50,width:50,color:'#fff'}}/>
</Box>


<Typography textAlign='center' sx={{ml:3,mr:3,fontWeight:'600',mt:2}}>Live Alerts<br/>
on PC / Laptop</Typography>


</Box>
</Paper>
</Grid>
<Grid item size={{xs:6,sm:4}}>
<Paper elevation={1} sx={{height:200,width:'100%',backgroundColor:'#fff',borderRadius:10}}>
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<Box sx={{height:80,width:80,backgroundColor:'#09488b',borderRadius:25,mt:3,display:'flex',justifyContent:'center',alignItems:'center'}}>
<CloudDoneIcon sx={{height:50,width:50,color:'#fff'}}/>
</Box>


<Typography textAlign='center' sx={{ml:3,mr:3,fontWeight:'600',mt:2}}>Non repaint<br/>
Indicator</Typography>


</Box>
</Paper>
</Grid>
<Grid item size={{xs:6,sm:4}}>
<Paper elevation={1} sx={{height:200,width:'100%',backgroundColor:'#fff',borderRadius:10}}>
<Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

<Box sx={{height:80,width:80,backgroundColor:'#09488b',borderRadius:25,mt:3,display:'flex',justifyContent:'center',alignItems:'center'}}>
<EqualizerIcon sx={{height:50,width:50,color:'#fff'}}/>
</Box>


<Typography textAlign='center' sx={{ml:3,mr:3,fontWeight:'600',mt:2}}>Past performance<br/>
to any stock</Typography>


</Box>
</Paper>
</Grid>


  </Grid>
</Box>
    </Grid>
   </Grid>
  </Container>
</Box>




<Box sx={{backgroundColor:'#f8f6f6',minHeight:100,mt:2}}>

<Box sx={{maxHeight:300,pt:2,pb:2}}>
 <Container maxWidth='lg'>
<Card sx={{maxHeight:200,backgroundColor:'white',borderRadius:5}}>
<br/>
<Grid container spacing={1}>
<Grid item size={{xs:4,sm:4}}>

<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<LocationCityIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+22</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Cities</Typography>
</Box>
</Box>
</Grid>
<Grid item size={{xs:4,sm:4}}>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<AddHomeIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold'}}>+300</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>Happy Customers</Typography>
</Box>
</Box>
</Grid>
<Grid item size={{xs:4,sm:4}}>
<Box sx={{display:'flex',justifyContent:'center'}}>
<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
<GradeIcon sx={{height:{xs:35,sm:35,md:50},width:{xs:25,sm:25,md:50},color:'#00337b'}}/>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>+1500</Typography>
<Typography align='center' sx={{fontSize:{xs:12,sm:12,md:16},fontFamily:'sans-serif',fontWeight:'bold',}}>4.5+ Ratings</Typography>
</Box>
</Box>
</Grid>
</Grid>
<br/>
</Card>
</Container>
 </Box>
</Box>


      </Box>
    );
  }
}

export default Landingpage;


const listInstruction = [
  'Mtel Tech does not hold registration as an investment advisor with SEBI.',
  'All the software are provided for educational perpouse only.',
  'User are advised to consult with his/her finnacial advisor befor trade.',
  'We dont provide tips or calls',
  'We are not giving any recomendation on buy/ sell of shares.',
  'We are here only to educate people about stock market. Hence we are not responsible for loss/profit.'
]

/*
<Box sx={{display:'flex',flexDirection:'row',justifyContent:{xs:'center',sm:'center',md:'left'},alignItems:'center'}}>

<Box sx={{display:'flex',flexDirection:'row',mt:5,justifyContent:'space-between',alignItems:'center',mr:1}}>
<Box   onClick={() =>window.open("https://wa.me/7801918155")} sx={{height:{xs:35,sm:35},minWidth:100,backgroundColor:'#00cfe8',display:'flex',justifyContent:'center',alignItems:'center'}}>
 <WhatsAppIcon sx={{height:15,width:15,color:'#fff',ml:0.5}}/>
<Typography sx={{fontWeight:'500',color:'#fff',ml:0.5,mr:0.5,fontSize:12}}>JOIN WHATSAPP -> COMMUNITY</Typography>
</Box>

<Box sx={{height:{xs:35,sm:35},minWidth:100,ml:2,display:'flex',justifyContent:'center',alignItems:'center',border:1,borderColor:'#fff'}}>
<AutoGraphIcon sx={{height:20,width:20,color:'#fff',mt:0.2,mr:1}}/>
<Typography sx={{fontWeight:'500',color:'#fff',fontSize:12}}>PLAN & PRICING</Typography>
<CallMadeOutlinedIcon sx={{height:20,width:20,color:'#fff',mt:0.2,ml:1}}/>
</Box>
</Box>


</Box>
*/
/*
  <Box
          sx={{
            width: "100%",
            backgroundColor: "#07497b",
            display: "flex",
            justifyContent: "left",
          }}
        >
          <Container maxWidth="xl">
            <Tabs
              scrollButtons="auto"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label="NSE Indices"
                onClick={() => this.setState({ value: "one" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                wrapped
              />
              <Tab
                value="two"
                onClick={() => this.setState({ value: "two" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                label="BSE Indices"
              />
              <Tab
                value="three"
                onClick={() => this.setState({ value: "three" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                label="Loss making shares"
              />
              <Tab
                value="four"
                onClick={() => this.setState({ value: "four" })}
                sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                label="Profit making share"
              />
            </Tabs>
          </Container>
        </Box>

        <Divider />

        <Box sx={{ minHeight: 500 }}>
          {
              this.state.value=='one'? <Nseindices nsedata = {this.state.nse} /> :this.state.value=='two'? <Bseindices nsedata={this.state.bse} /> : this.state.value=='three'?<LossmakingShares nsedata={this.state.topLoser} /> : this.state.value=='four'?<ProfitMakingShares nsedata={this.state.topgainer}/> :  <Nseindices nsedata = {this.state.nse} />
          }
         
        </Box>

        <Box sx={{ minHeight: 300, width: "100%", backgroundColor: "#fff" }}>
          <Container maxWidth="xl">
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 20, sm: 22, md: 25, lg: 25 },
                fontWeight: "600",
                mt: 2,
                ml: 2,
                mt: 2,
              }}
            >
              Our Customers
            </Typography>
            <Box sx={{ backgroundColor: "#f5f3f3" }}>
              <Scroller />
            </Box>

            <br />

            <Box
              sx={{
                backgroundImage: `url(${map2})`,
                minHeight: 300,
                backgroundColor: "#022246 ",
                opacity: 0.9,
              }}
            >
              <Box
                sx={{ ml: { xs: "0%", sm: "2%" }, mr: { xs: "0%", sm: "2%" } }}
              >
                <br />
                <Typography
                  align="justify"
                  variant="h1"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "545",
                    fontFamily: "sans-serif",
                    color: "white",
                    fontSize: { xs: 22, sm: 22, md: 30, lg: 30 },
                  }}
                >
                  Who we are ?.
                </Typography>
                <Divider />
                <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
                  A company listed on the stock market offers shares for public
                  purchase. These shares represent ownership in the company.
                  Investors buy and sell shares, hoping for price appreciation
                  or dividends. The stock market is influenced by economic
                  factors, company performance, and market sentiment.
                </Typography>
                <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
                  A company listed on the stock market offers shares for public
                  purchase. These shares represent ownership in the company.
                  Investors buy and sell shares, hoping for price appreciation
                  or dividends. The stock market is influenced by economic
                  factors, company performance, and market sentiment.
                </Typography>
                <Typography
                  align="justify"
                  sx={{
                    m: 1,
                    mb: 2,
                    fontWeight: "335",
                    fontFamily: "Roboto Slab",
                    color: "#fff",
                    fontSize: { xs: 13, sm: 13, md: 15, lg: 15 },
                  }}
                >
                  Shares are generally highly liquid, meaning you can buy and
                  sell them easily
                </Typography>
                <br />
              </Box>
            </Box>
          </Container>
        </Box>
        */